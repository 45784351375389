<template>
  <div
    class="card"
    @click="$emit('click', $event)"
    :class="{ loading, fullscreen }"
  >
    <div class="card-header" v-if="title || menu.length > 0">
      <nav class="nav" v-if="title">
        <span class="card-title">{{ title }}</span>
      </nav>

      <nav class="nav ml-auto" style="width: unset" v-if="menu">
        <span
          class="btn btn-sm bg-white p-0"
          v-if="item.click"
          v-for="(item, i) in _menu"
          :key="i"
          @click="item.click"
        >
          <i v-if="item.icon" :class="item.icon" :style="item.style"></i>
          <span v-model="item.text">{{ item.text }}</span>
        </span>

        <span
          class="btn btn-sm bg-white p-0 events-disable"
          v-if="!item.click"
          v-for="(item, i) in _menu"
          :key="i"
        >
          <i v-if="item.icon" :class="item.icon"></i>
          <span v-model="item.text">{{ item.text }}</span>
        </span>
      </nav>
    </div>

    <slot v-if="!is_minimize"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: [String, Number],
    loading: Boolean,
    noHeader: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      is_minimize: false,
      is_fullscreen: false,
      buttons: {
        refresh: {
          click: this._refresh,
          icon: "i-refresh-cw"
        },
        minimize: {
          click: this._minimize,
          icon: this.is_minimize ? "i-square" : "i-minus"
        },
        fullscreen: {
          click: this._fullscreen,
          icon: this.is_minimize ? "i-minimize" : "i-maximize"
        },
        close: {
          click: this.close,
          icon: "i-close"
        }
      }
    };
  },
  computed: {
    _menu() {
      let res = [];

      this.menu.map(item => {
        if (typeof item === "string" && this.buttons[item])
          res.push(this.buttons[item]);

        if (typeof item === "object") res.push(item);
      });

      return res.length > 0 ? res : undefined;
    },

    fullscreen: {
      get() {
        return this.is_fullscreen;
      },

      set(val) {
        let requestMethod =
          this.$el.requestFullScreen ||
          this.$el.webkitRequestFullScreen ||
          this.$el.mozRequestFullScreen ||
          this.$el.msRequestFullScreen;
        let cancelMethod =
          document.cancelFullScreen ||
          document.webkitCancelFullScreen ||
          document.mozCancelFullScreen ||
          document.exitFullscreen;

        this.is_fullscreen = val;

        if (this.is_fullscreen && requestMethod) requestMethod.call(this.$el);

        if (!this.is_fullscreen && cancelMethod) cancelMethod.call(document);
      }
    }
  },
  methods: {
    _refresh() {
      this.$emit("refresh");
    },
    _minimize() {
      this.is_minimize = !this.is_minimize;
      this.$emit(this.is_minimize ? "minimize" : "maximize");
    },
    _fullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$emit("fullscreen");
    },

    close() {
      this.$emit("close");
      this.$el.outerHTML = "";
    }
  }
};
</script>
